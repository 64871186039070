import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Image3 from "./image3";
import Image4 from "./image4";

export const ServicesPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient">
      <div className="container" style={{ maxWidth: "746px" }}>
        <div className="section">
          <div style={{ display: "grid", placeItems: "center" }}>
            <Image3 />
          </div>
          <div className="content">
            <h3
              className="title has-text-weight-semibold is-size-3 my-5 is-family-secondary"
              id="services-adults"
              style={{ color: "#6ba696" }}
            >
              Υπηρεσίες Ενηλίκων
            </h3>
            <h3>Ατομική Ψυχοθεραπεία</h3>
            <p>
              Η ψυχοθεραπεία είναι μία επιστημονικά τεκμηριωμένη μεθοδος
              αντιμετώπισης ψυχολογικών δυσκολιών. Η ψυχοθεραπευτική διαδικασία
              συνιστά ένα ασφαλές πλαίσιο εντός του οποίου μπορόυν να τεθούν υπό
              επεξεργασία τα ζητήματα που βιώνει ο θεραπεύόμενος. Μπορεί να
              στοχεύει στην απομάκρυνση συμπτωμάτων, στη διαχείριση δύσκολων
              συναισθημάτων και ενδοψυχικών συγκρούσεων, στην αυτογνωσία, στη
              βελτίωση των διαπροσωπικών σχέσεων κ.α.
            </p>
            <h3>Ατομική Συμβουλευτική</h3>
            <p>
              Η συμβουλευτική είναι μία υποστηρικτική διαδικασία κατά την οποία
              δίνεται έμφαση στην ενίσχυση των ικανοτήτων του ατόμου προκειμένου
              να ανταποκριθεί στις απαιτήσεις της πραγματικότητας και να
              βελτιώσει την ποιότητα της ζωής του.
            </p>
            <h3>Συμβουλευτική Ζεύγους</h3>
            <p>
              Η συμβουλευτική ζεύγους μπορεί να στοχεύει στην αναγνώριση των
              συναισθημάτων, την επεξεργασία των συγκρούσεων και την βελτίωση της
              επικοινωνιας μεταξύ των μελών του ζευγαριού σχετικά με τα ζητήματα
              που αναδύονται στη σχέση.
            </p>
            <h3>Ομαδική Ψυχοθεραπεία</h3>
            <p>
              Η ομαδική, όπως και η ατομική ψυχοθραπεία, είναι μία μέθοδος
              αντιμεωπισης ψυχολογικών δυσκολιών. Μέσα στο ομαδικό πλαίσιο οι
              θεραπευόμενοι εχουν τη δυνατότητα να επεξεργαστούν τις δυσκολίες
              τους σε ένα πλαισιο αλληλεπίδρασης και μοιράσματος με τα άλλα
              μελη.
            </p>
            <h3>Εξ’ Αποστάσεως Ψυχοθεραπεία</h3>
            <p>
              Η ψυχοθεραπεία εξ’αποστάσεως προτείνεται στις περιπτώσεις όπου δεν
              υπάρχει η δυνατότητα για προσέλευση στον χώρο μας.
            </p>
          </div>{" "}
          <div style={{ display: "grid", placeItems: "center" }}>
            <Image4 />
          </div>
          <div className="content">
            <h3
              className="title has-text-weight-semibold is-size-3 my-5 is-family-secondary"
              style={{ color: "#6ba696" }}
            >
              Υπηρεσίες Παιδιών και Εφήβων
            </h3>
            <h3 id="services-teenagers">
              Ψυχολογική Αξιολόγηση Παιδιών και Εφήβων
            </h3>
            <p>
              Η ψυχολογική αξιολόγηση περιλαμβάνει τη λήψη αναπτυξιακού και
              κοινωνικού ιστορικού από τους γονείς και την πραγματοποίηση
              διαγνωστικών συναντήσεων με το παιδί. Έπειτα προτείνεται η
              κατάλληλη θεραπευτική αντιμετώπιση για την αντιμετώπιση των
              ψυχολογικών δυσκολιών που λαμβάνουν χώρα.
            </p>
            <h3>Ψυχοθεραπεία Παιδιών και Εφήβων</h3>
            <p>
              Η ψυχοθεραπεία παρέχει στα παιδιά και στους εφήβους ένα ασφαλές
              πλαίσιο όπου μπορούν να εκθέσουν και να διαπραγματευτούν τα
              ζητήματα που τους απασχολούν. Η ψυχοθεραπεία παιδιών
              διαφοροποιείται από αυτή των εφήβων και των ενηλίκων καθώς τα
              παιδιά δεν έχουν τις ίδιες δεξιότητες έκφρασης μέσω του λόγου,
              όπως οι έφηβοι και οι ενήλικες.
            </p>
            <p>
              Γι αυτό το λόγο τα ζητήματα που αντιμετωπίζουν τα παιδιά γίνονται
              κατανοητά από εναλλακτικές μορφές έκφρασης, όπως η ζωγραφική και
              το παιχνίδι, ανάλογα το αναπτυξιακό στάδιο στο οποίο βρίσκονται. Η
              ψυχοθεραπεία εφήβων δανείζεται τεχνικές από την ψυχοθεραπεία
              παιδιών και από την ψυχοθεραπεία ενηλίκων, εφόσον ο/η έφηβος/η
              έχει αναπτύξει επαρκώς δεξιότητες έκφρασης μέσω του λόγου.
            </p>
            <p>
              Τα ζητήματα που μπορεί να χρήζουν ψυχοθεραπείας μπορεί να έχουν
              κάνουν με δυσκολίες προσαρμογής, κατάθλιψη, άγχος. αρνητική
              αυτό-εικόνα, καθώς και συμπτώματα που υποδηλώνουν την παρουσία
              ψυχο-συναισθηματικών δυσκολιών (πχ. νυχτερινή ενούρηση,
              τραυλισμός, εναντιωματική συμπεριφορά, ψυχοσωματικά συμπτώματα,
              διάσπαση προσοχής, υπερκινητικότητα, διαταραχές στον ύπνο ή τη
              διατροφή, αυτό-τραυματισμός, τικ, σχολική άρνηση κ.α.)
            </p>
            <h3>Συμβουλευτική Γονέων</h3>
            <p>
              Η συμβουλευτική γονέων είναι απαραίτητη για μία ολοκληρωμένη
              παρέμβαση που στοχεύει στην απομάκρυνση ή τη βελτίωση των
              ψυχολογικών δυσκολιών του παιδιού/εφήβου.
            </p>
            <p>
              Αυτό συμβαίνει επειδή στα αναπτυξιακά στάδια που προηγούνται της
              ενηλικίωσης το άτομο είναι εξαρτημένο από το οικογενειακό
              περιβάλλον και συχνά τα συμπτώματα που εκδηλώνονται κατά την
              ανήλικη ζωή αποτελούν δυσκολίες προσαρμογής σε αυτό. Επομένως
              απαιτείται η ενεργή εμπλοκή των γονέων και η κατάλληλη προσαρμογή
              τους στις ανάγκες του παιδιού προκειμένου να δημιουργηθούν οι
              συνθήκες που ευνοούν την ψυχό-συναισθηματική του ωρίμανση.
            </p>
            <p>
              Η συμβουλευτική γονέων στοχεύει στην κατανόηση των δυσκολιών του
              παιδιού/εφήβου, στην κατανόηση των δυσκολιών στη σχέση των γονιών
              με το παιδί, στη βελτίωση της επικοινωνίας μεταξύ γονέων και
              παιδιών καθώς και στη διερεύνηση τρόπων με των οποίους οι γονείς
              μπορούν να παρέχουν στο παιδί/ έφηβο ένα ασφαλές περιβάλλον
              ανάπτυξης.
            </p>
            <h3>Ομάδες Διάδρασης</h3>
            <p>
              Οι ομάδες διάδρασης αποτελούνται από παιδιά κοινού αναπτυξιακού
              σταδίου και στοχεύουν στην εξάσκηση και βελτίωση των κοινωνικών
              δεξιοτήτων μέσα από τη δημιουργία σχέσεων με συνομηλίκους και την
              αλληλεπίδραση με αυτούς.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

ServicesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const ServicesPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ServicesPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServicesPage;

export const ServicesPageQuery = graphql`
  query ServicesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
